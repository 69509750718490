import React, { useContext, useState } from "react";
import styled from "styled-components";
import ReactFlagSelect from "react-flags-select";
import { RightArrowIcon } from "./Icons";
import { MyContext } from "./SimpleForm";
import { client } from "../utils";
import { BASE_URL } from "../keys/urls";
import "react-flags-select/css/react-flags-select.css";
import Switch from "react-switch";
import "./Phone.css"


const Wrapper = styled.div`
	form {
		display: flex;
		align-items: center;
	}

	.input-submit input {
		padding: 0.4rem 0.6rem;
		background-color: none;
		height: 38px;
		border-left: 1px solid #1782b8;
		border-top: 1px solid #1782b8;
		border-bottom: 1px solid #1782b8;
		border-right: ${props => (props.showForm ? "none" : "1px solid #1782b8")};
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-left-radius: ${props => (props.showForm ? "0px" : "4px")};
		border-top-left-radius: ${props => (props.showForm ? "0px" : "4px")};
		width: 200px;
	}

	.input-submit {
		display: flex;
		align-items: center;
		position: relative;
	}

	.input-submit span {
		position: absolute;
		top: 40px;
		font-size: 0.9rem;
		color: #dd2d5f;
		display: block;
		padding-top: 0.5rem;
		text-transform: initial;
	}

	.submit-icon {
		width: 38px;
		height: 38px;
		background-color: #1782b8;
		display: flex;
		align-items: center;
		padding-left: 0.4rem;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.submit-icon svg {
		fill: #fff;
		cursor: pointer;
	}

	input[disabled] {
		background: none;
		opacity: 0.9;
		cursor: not-allowed;
	}

	input:focus,
	button:focus,
	button:active {
		outline: none;
	}
`;

const SelectPhoneNumber = ({ blackList, trigger, triggerNextStep }) => {
	const { setState } = useContext(MyContext);
	const [showForm, setShowForm] = useState(true);
	const [countryCode, setCountryCode] = useState(blackList ? "US" : "IN");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [showError, setShowError] = useState(false);
	const [optIn,setOptIn] = useState(true);

	const handleChange = (checked)=> {
		setOptIn(checked);
	  }

	const handleFormSubmit = async e => {
		e.preventDefault();

		// regex are boring :)
		if (
			phoneNumber.trim().length === 10 &&
			String(parseInt(phoneNumber.trim())).length === 10
		) {
			setShowForm(false);

			const { response } = await client(`${BASE_URL}crm/get-contry`, {
				country_code: countryCode
			});

			const phone_number = response.callingCodes[0]
				? response.callingCodes[0] + phoneNumber
				: phoneNumber;

			setState(prev => ({
				...prev,
				phone: { phone_number, country: response.name },
				optIn
			}));

			triggerNextStep({
				trigger
			});
		} else {
			setShowError(true);
		}
	};

	return (
		<Wrapper showForm={showForm}>
			<ul className="ul-swich-section">
				<li>
				<img style={{}} src={require("../img/img/whatsapp.png")} width='30' height='30'/>

				</li>
				<li>
				<Switch onChange={handleChange}
			checked={optIn}
			uncheckedIcon={
				<div style={{color:'white',fontWeight:600,padding:"4px 0 4px 8px"}}>N</div>
			}

			checkedIcon={
				<div style={{color:'white',fontWeight:600,padding:"4px 0 4px 8px"}}>Y</div>
			}
			/>
				</li>
				<li><span>Opt-in</span></li>
			</ul>





			<form onSubmit={handleFormSubmit}>
				{blackList && (
					<ReactFlagSelect
						defaultCountry={"US"}
						countries={["IN"]}
						blackList={true}
						showSelectedLabel={false}
						onSelect={countryCode => setCountryCode(countryCode)}
					/>
				)}

				{!blackList && (
					<ReactFlagSelect
						defaultCountry={"IN"}
						showSelectedLabel={false}
						onSelect={countryCode => setCountryCode(countryCode)}
					/>
				)}

				<div className="input-submit">
					<input
						disabled={!showForm}
						type="text"
						value={phoneNumber}
						onChange={e => {
							setShowError(false);
							setPhoneNumber(e.target.value);
						}}
						placeholder="Phone Number"
						required
					/>

					{showError && <span>Please provide valid phone number</span>}

					{showForm && (
						<div onClick={handleFormSubmit} className="submit-icon">
							<RightArrowIcon />
						</div>
					)}
				</div>
			</form>
		</Wrapper>
	);
};

export default SelectPhoneNumber;
