import React, { useState } from "react";
import "./App.css";
import SimpleForm from "./components/SimpleForm";

const App = props => {
	let [showChat, setShowChat] = useState(false);
	return (
		<>
			<div className="bot" id="mybot">
				{/* {console.log("Iam ShowChat: ", showChat)} */}
				<SimpleForm setShowChat={setShowChat} showChat={showChat}></SimpleForm>
				{/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
				{ showChat ? (<div className="copy_rights" >
					Powered By QAPS Engine<br/>
					A Mestva Product &copy;
					</div>) : ''}
			</div>
			
		</>
	);
};

export default App;
