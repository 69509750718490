import React, {
	useEffect,
	useState,
	useContext} from "react";
import { BASE_URL } from "../keys/urls";

import ChatBot from "react-simple-chatbot";
import ContactNumber from "./contactNumber";
import Slots from "./Slots";
import Def from "./Def";
import Confirm from "./Confirm";
import {
	uuid,
	phoneNumberValidator,
	ageValidator,
	nameValidator
} from "../utils";
import ChatBoxHeader from "./ChatBoxHeader";
import SelectPhoneNumber from "./SelectPhoneNumber";
import GetUserInput from "./GetUserInput";
import GetDepartments from "./GetDepartments";
import GetLocation from "./GetLocation";
import GetSurgeries from "./GetSurgeries";
import exploreMore from "./flow/exploreMore";
import ContactUs from "./contactUs"
import ChatRoom from "./livechat/ChatRoom"

export const MyContext = React.createContext({});

function SimpleForm({ setShowChat, showChat }) {
	const [phoneNumberResponse, setPhoneNumberResponse] = useState(null);
	const [dep_n, setDep] = useState({});
	const [state, setState] = useState({});
	const [drop, setDrop] = useState("");
	const [locId, setLocId] = useState({});
	const [logo, setLogo] = useState({});


	// unique chatbotkey
	const [chatbotKey, setChatbotKey] = useState(uuid());

	// Use Effect
	useEffect(() => {
		const apiURL = `${BASE_URL}appoiment-portal/doctors/30`;
		fetch(apiURL, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				Accept: "application/json"
			}
		})
			.then(resp => resp.json())
			.then(resp => {
				setLogo(resp.response)
				// this.setState({ slots:})
			})
			.catch(err => console.log(err));
	}, []);
	// Use Effect End


	const setField = (key, value) => {
		// setData(prev => {
		//   console.log({key, value, prev})
		//   let newData = {...prev}
		//   console.log({ newData })
		//   newData[key] = value
		//   console.log({ newData })
		//   return newData
		// })
		setDep(() => 2);
	};

	const setLocaton = (key, value) => {
		setLocId(() => 2);
	};

	const setDropVal = () => {
		setDrop("test");
	};

	const clearChat = () => {
		setState({});
		setChatbotKey(uuid());
	};

	const hideChat = () => setShowChat(false); // hide the chat when this function invoked
	return (
		<MyContext.Provider value={{ state, setState}}>
			
			<h2 className="asknu">Ask Gaia</h2>
			<ChatBot
				floating={true}
				// floatingIcon={require("../img/icon1.gif")}
				opened={showChat}
				toggleFloating={e => setShowChat(e.opened)}
				headerComponent={
					<ChatBoxHeader clearChat={clearChat} hideChat={hideChat} />
				}
				botAvatar={require("../img/replyicon.png")}
				width="400px"
				height="625px"
				// headerTitle="NU Hospitals"
				key={chatbotKey}
				steps={[
					{
						id: "welcome",
						message: "Welcome to NU Fertility",
						trigger: "greet"
					},
					{
						id: "greet",
						message: `I am Gaia, a virtual agent.`,
						trigger: "assist"
					},

					{
						id: "assist",
						message: "How can I assist you on these options?",
						// trigger: 'slots',
						trigger: "appoinment-options"
					},

					{
						id: "appoinment-options",
						options: [
							{
								value: "appointment-doctors",
								label: "Appointment with doctor",
								trigger: "sure"
							},
							{
								value: "find-doctor",
								label: "Find doctor",
								// trigger: "yourself_or_someone"
								trigger: "same_or_other"
							},
							{
								value: "book-video-consultation",
								label: "Book video consultation",
								trigger: "sure_video_consulting"
							},
							{
								value: "surgery_enquery",
								label: "Surgery enquiry",
								trigger: "surgery_enquary_start"
							},
							{
								value: "international-enq",
								label: "International patient query",
								// trigger: "select_international_additional_info"
								trigger: "international_same_or_other"
							},
							{
								value: "talk-with-us",
								label: "Connect with us",
								trigger: 'talk-with-us'
								// trigger: "explore-more-prompt"
							},

							{
								value: "livechat",
								label: "Connect with our Customercare",
								trigger: 'connectwithagent'
								// trigger: "explore-more-prompt"
							}
							
						]
					},
					{"id":'talk-with-us',
					component:<ContactUs/>
				},

				{
					id:'connectwithagent',
					component:<ChatRoom/>,
					end:true

				},
					// International Patient Enquiry
					// { id: "fetching", message: "fdfdsfdsf" },
					{
						id: "international_same_or_other",
						message: "Do you want to enquire for yourself or someone else?",
						trigger: "international_yourself_or_someone"
					},
					
					{
						id: "international_yourself_or_someone",
						options: [
							{
								value: "Self",
								label: "Self",
								trigger: "privacy_policy_"
							},
							{
								value: "Someone else",
								label: "Someone else",
								trigger: "privacy_policy_"
							}
						]
					},
					{
						id: "inter_gender",
						message: "Please provide your gender",
						trigger: "international_gender"
					},
					{
						id: "international_gender",
						options: [
							{ value: "male", label: "Male", trigger: "international_contact" },
							{
								value: "female",
								label: "Female",
								trigger: "international_contact"
							}
						]
					},
					//----------------------------------------------------------------------
					{
						id: "privacy_policy_",
						component: (
							<div className="privacy-policy">
								Privacy policy{" "}
								<a
									href="https://www.nuhospitals.com/privacy-policy"
									target="_blank"
								>
									Click Here
								</a>{" "}
								please read and accept the privacy policy to proceed further
							</div>
						),
						trigger: "privacy_option_" //selectgender
					},
					{
						id: "privacy_option_",
						options: [
							{
								value: "agree",
								label: "Agree",
								trigger: "international_name"
							},
							{ value: "no_a gree", label: "No", trigger: "exit_trig" }
						]
					},

					//----------------------------------------------------------------------
					{
						id: "international_name",
						message: "Enter patient full name",
						trigger: "international_name_input"
					},
					{
						id: "international_name_input",
						user: true,
						validator: nameValidator,
						trigger: "international_patient_age"
					},
					{
						id: "international_patient_age",
						message: "May I know your age",
						trigger: "international_patient_age_input"
					},
					{
						id: "international_patient_age_input",
						user: true,
						validator: ageValidator,
						trigger: "inter_gender"
					},
					{
						id: "international_contact",
						message: "Enter patient contact number",
						trigger: "international_contact_input"
					},
					{
						id: "international_contact_input",
						component: (
							<SelectPhoneNumber
								blackList={true}
								trigger="international_branch"
							/>
						)
					},
					{
						id: "international_additional_info",
						message: "Do you want to provide any additional info?",
						trigger: "international_additional_info_options"
					},
					{
						id: "international_additional_info_options",
						options: [
							{
								label: "Yes",
								value: "yes",
								trigger: "select_international_additional_info"
							},
							{ label: "No", value: "no", trigger: "video_confirm_confirmbook" }
						]
					},
					{
						id: "select_international_additional_info",
						component: (
							<GetUserInput
								trigger="video_confirm_confirmbook"
								placeholder="Enter additional details"
								field="additional_info"
							/>
						)
					},
					{
						id: "international_branch",
						message: "Please select your branch",
						trigger: "select_international_branch"
					},
					{
						id: "select_international_branch",
						component: <GetLocation trigger="select_dept" />
					},
					{
						id: "select_dept",
						message: "Please select your speciality",
						trigger: "international_selectdept"
					},
					{
						id: "international_selectdept",
						component: <GetDepartments trigger="available_doctors" />
					},

					// International Patient Enquiry End

					// Start Surgery Enquiry
					{
						id: "surgery_enquary_start",
						message:
							"Sure, we will assist you with surgery/procedure list at our hospitals",
						trigger: "patient_user_name_privacy"
					},
					{
						id: "patient_user_name_privacy",
						component: (
							<div className="privacy-policy">
								Privacy policy{" "}
								<a
									href="https://www.nuhospitals.com/privacy-policy"
									target="_blank"
								>
									Click Here
								</a>{" "}
								Please read and accept the privacy policy to proceed further
							</div>
						),
						trigger: "my_options_privacy"
					},
					{
						id: "my_options_privacy",
						options: [
							{
								value: "agree",
								label: "Agree",
								trigger: "patient_user_name"
							},
							{ value: "no_a gree", label: "No", trigger: "exit_trig" }
						]
					},
					{
						id: "patient_user_name",
						message: "Enter patient full name",
						trigger: "enq_username"
					},
					{
						id: "enq_username",
						user: true,
						validator: nameValidator,
						trigger: "patient_gender"
					},
					{
						id: "patient_gender",
						message: "May I know your gender",
						trigger: "enq_patient_gender"
					},
					{
						id: "enq_patient_gender",
						options: [
							{ value: "male", label: "Male", trigger: "enq_patient_age" },
							{ value: "female", label: "Female", trigger: "enq_patient_age" }
						],
						trigger: "enq_patient_age"
					},
					{
						id: "enq_patient_age",
						message: "May I know your age",
						trigger: "enq_patient_age_input"
					},
					{
						id: "enq_patient_age_input",
						user: true,
						trigger: "enq_patient_contact",
						validator: ageValidator
					},
					{
						id: "enq_patient_contact",
						message: "Please provide patient's contact number",
						trigger: "enq_patient_contact_input"
					},
					{
						id: "enq_patient_contact_input",
						component: <SelectPhoneNumber trigger="surgery_branch_message" />
					},
					{
						id: "surgery_branch_message",
						message: "Please select your branch",
						trigger: "select_surgery_branch"
					},
					{
						id: "select_surgery_branch",
						component: <GetLocation trigger="surgery_dept_message" />
					},
					{
						id: "surgery_dept_message",
						message: "Please choose speciality",
						trigger: "select_surgery_dept"
					},
					{
						id: "select_surgery_dept",
						component: <GetDepartments trigger="surgery_name" />
					},
					{
						id: "surgery_name",
						message:
							"These all some of our available surgeries. Please choose surgery options",
						trigger: "surgery_list"
					},
					{
						id: "surgery_list",
						component: <GetSurgeries trigger="i_want_to_provide" />
					},
					{
						id: "enter_surgery_name_message",
						message: "Please enter your surgery name",
						trigger: "surgery_name_message"
					},
					{
						id: "surgery_name_message",
						component: (
							<GetUserInput
								trigger="i_want_to_provide"
								field="surgery_name"
								placeholder="Enter surgery name"
							/>
						)
					},
					{
						id: "i_want_to_provide",
						message: "Please provide the additional information",
						trigger: "additional_data"
					},

					{
						id: "additional_data",
						component: (
							<GetUserInput
								trigger="confirm_book_"
								field="additional_info"
								placeholder="Patient history/Any other details"
							/>
						)
					},
					{
						id: "confirm_book_",
						component: <Confirm context={MyContext} />
					},
					{
						id: "surgery_thank_you",
						message:
							"Thank you for submitting your details our team would get it touch with you shortly",
						end:true
					},

					{
						id: "surgery_confirm_msg",
						message: "Do you want to confirm the booking",
						trigger: "surgery_confirm_options"
					},
					{
						id: "surgery_confirm_options",
						options: [
							{ label: "Yes", value: "yes", trigger: "confirm_book_" },
							{ label: "No", value: "no", trigger: "greet" }
						]
					},
					// {
					// 	id: "confirm_book_",
					// 	component: <Confirm context={MyContext} />
					// },

					// End Surgery Enquiry
					{
						id: "sure_video_consulting",
						message:
							"Sure, I will help you with book video consultation of our doctors.",
						trigger: "same_or_other_video_consultation"
					},
					{
						id: "same_or_other_video_consultation",
						message:
							"Do you want book video consultation yourself or someone else?",
						trigger: "some_or_other_video_consulting"
					},
					{
						id: "some_or_other_video_consulting",
						options: [
							{
								value: "Self",
								label: "Self",
								trigger: "privacy_selectgender_video_consulting"
							},
							{
								value: "Someone else",
								label: "Someone else",
								trigger: "privacy_selectgender_video_consulting"
							}
						]
					},
					// privacy start
					{
						id: "privacy_selectgender_video_consulting",
						component: (
							<div className="privacy-policy">
								Privacy policy{" "}
								<a
									href="https://www.nuhospitals.com/privacy-policy"
									target="_blank"
								>
									Click Here
								</a>{" "}
								Please read and accept the privacy policy to proceed further
							</div>
						),
						trigger: "selectgender_video_consulting_options" //selectgender
					},
					{
						id: "selectgender_video_consulting_options",
						options: [
							{
								value: "agree",
								label: "Agree",
								trigger: "video_consulting_user_name"
							},
							{ value: "no_a gree", label: "No", trigger: "exit_trig" }
						]
					},
					// privacy end
					// Video Consulting Data Collection
					{
						id: "selectgender_video_consulting",
						message: "May I know your gender?",
						trigger: "gender_video_consulting"
					},
					{
						id: "gender_video_consulting",
						options: [
							{
								value: "male",
								label: "Male",
								trigger: "Video_consulting_Enterphone"
							},
							{
								value: "female",
								label: "Female",
								trigger: "Video_consulting_Enterphone"
							}
						]
					},
					{
						id: "Enterage_video_consulting",
						message: "May I know your age?",
						trigger: "video_consulting_user_age",
					},
					{
						id: "video_consulting_user_age",
						user: true,
						validator: ageValidator,
						trigger: "selectgender_video_consulting"
					},
					{
						id: "video_consulting_user_name",
						message: "Enter patient full name",
						trigger: "video_consulting_usernameinput"
					},
					{
						id: "video_consulting_usernameinput",
						user: true,
						validator: nameValidator,
						trigger: "Enterage_video_consulting"
					},
					{
						id: "Video_consulting_Enterphone",
						message: "Enter patient phone number",
						trigger: "video_consulting_mobile"
					},

					{
						id: "video_consulting_mobile",
						component: <SelectPhoneNumber trigger="video_branch" />
					},
					{
						id: "video_branch",
						message: "Please select your branch",
						trigger: "select_video_branch"
					},
					{
						id: "select_video_branch",
						component: <GetLocation trigger="video_dept" />
					},
					{
						id: "video_dept",
						message: "Click for Speciality",
						trigger: "video_selectdept"
					},
					{
						id: "video_selectdept",
						component: <GetDepartments trigger="available_doctors" />
					},
					{
						id: "available_doctors",
						message: "Available doctors are ...",
						// trigger: "sure_find_doctors"
						trigger: "slots"
					},
					{
						id: "option_video_consulting",
						message: "Please confirm if you want to book an appointment?",
						trigger: "option_videos"
					},
					{
						id: "option_videos",
						options: [
							{
								value: "yes",
								label: "Yes",
								trigger: "international_additional_info"
							},
							{ value: "no", label: "No", trigger: "slots" }
						]
					},
					{
						id: "video_confirm_confirmbook",
						component: <Confirm context={MyContext} />
					},

					// Video Consulting Data Collection End

					{
						id: "same_or_other",
						message({ steps }) {
							return steps["appoinment-options"] === "find-doctor"
								? "Do you want to find doctors your self or some one else"
								: "Do you want to book appointment for yourself or someone else?";
						},
						trigger: "yourself_or_someone"
					},

					{
						id: "same_or_other",
						message:
							"Do you want to book appointment for yourself or someone else?",
						trigger: "yourself_or_someone"
					},
					{
						id: "sure",
						message: "Sure, I will help you with your booking.",
						trigger: "same_or_other"
					},
					{
						id: "sure_find_doctors",
						message: "Sure, I will help you finding doctors.",
						trigger: "slots"
						// trigger: "sure_find_doctors_same_or_other"
					},
					// {
					// 	id: "sure_find_doctors_same_or_other",
					// 	message: "Do you want to find doctor for yourself or someone else",
					// 	trigger: "slots"
					// },
					{
						id: "location",
						message: "Please choose your location",
						trigger: "choose_location"
					},
					{
						id: "choose_location",
						component: <GetLocation trigger="dept" />
					},
					{
						id: "same_or_other",
						message:
							"Do you want to book appointment for yourself or someone else?",
						trigger: "yourself_or_someone"
					},

					{
						id: "yourself_or_someone",
						options: [
							{
								value: "Self",
								label: "Self",
								trigger: "privacy_policy"
							},
							{
								value: "Family/Friends",
								label: "Family/Friends",
								trigger: "privacy_policy"
							}
						]
					},
					{
						id: "privacy_policy",
						component: (
							<div className="privacy-policy">
								Privacy policy{" "}
								<a
									href="https://www.nuhospitals.com/privacy-policy"
									target="_blank"
								>
									Click Here
								</a>{" "}
								Please read and accept the privacy policy to proceed further
							</div>
						),
						trigger: "privacy_option" //selectgender
					},
					{
						id: "privacy_option",
						options: [
							{ value: "agree", label: "Agree", trigger: "user_name" },
							{ value: "no_a gree", label: "No", trigger: "exit_trig" }
						]
					},
					{
						id: "exit_trig",
						message: "Thanks for chatting",
						end: true
					},

					{
						id: "registerd_first_time",
						message: "Are you Registered First Time",
						trigger: "first_time_or_not"
					},
					{
						id: "first_time_or_not",
						options: [
							{ value: "yes", label: "Yes", trigger: "user_name" },
							{ value: "no", label: "No", trigger: "already-registered" }
						]
					},
					{
						id: "user_name",
						message: "Enter patient full name",
						trigger: "usernameinput"
					},
					{
						id: "usernameinput",
						user: true,
						validator: nameValidator,
						trigger: "Enterage"
					},
					{
						id: "Enterage",
						message: "May I know your age?",
						trigger: "age"
					},
					{
						id: "age",
						user: true,
						trigger: "selectgender",
						validator: ageValidator
					},
					{
						id: "selectgender",
						message: "May I know your gender?",
						trigger: "gender"
					},
					{
						id: "gender",
						options: [
							{ value: "male", label: "Male", trigger: "Enterphone" },
							{ value: "female", label: "Female", trigger: "Enterphone" }
						]
					},
					{
						id: "Enterphone",
						message: "Enter patient phone number",
						trigger: "mobile"
					},
					{
						id: "mobile",
						component: <SelectPhoneNumber trigger="location" />
					},

					{
						id: "dept",
						message: "Click for speciality",
						trigger: "selectdept"
					},
					{
						id: "selectdept",
						component: (
							// <Departments setData={setField} id={locId} context={MyContext} />
							<GetDepartments trigger="slots" />
						)
					},
					{
						id: "available_slots",
						message: "Available slots ...",
						trigger: "slots"
					},
					{
						id: "slots",
						component: (
							<Slots setData={setField} id={dep_n} context={MyContext} />
						),
						asMessage: false
					},
					// {
					//   id: 'slot_id',
					//   message: "Select your Slot ID and Confirn your booking.",
					//   trigger: 'slot_id',
					//   end: true,
					//   asMessage: false,

					// },
					{
						id: "confirm",
						component: <Def context={MyContext} />,
						trigger: "confirmmsg",
						asMessage: false
					},
					{
						id: "confirmmsg",
						// component: <Slots setData={setField} id={dep} context={MyContext}/>,
						message: "Do you want to confirm?",
						trigger: "option"
					},

					{
						id: "option",
						options: [
							// { value: "yes", label: "Yes", trigger: "finish" },
							{ value: "yes", label: "Yes", trigger: "confirmbook" },
							{ value: "no", label: "No", trigger: "slots" }
						]
					},
		
					{
						id: "confirmbook",
						component: <Confirm context={MyContext} />
					},
					{
						id: "finish",
						message: "Booking under review",
						trigger: "thanks"
					},
					{
						id: "thanks",
						message:
							"Thank you for submitting your details our team would get in touch with you shortly",
						end: true
					},

					{
						id: "error",
						message: "Cannot book now. Please try again!",
						trigger: "dept"
					},
					{
						id: "already-registered",
						message:
							"Iam Searching your Contact Details,\n Please Enter Your  Contact Number",
						trigger: "contactnumber"
					},
					{
						id: "contactnumber",
						user: true,
						validator: phoneNumberValidator,
						trigger: "user"
					},
					{
						id: "user",
						component: <ContactNumber />,
						waitAction: false,
						trigger: window.sessionStorage.getItem("status"),
						end:
							window.sessionStorage.getItem("status") == "user-not-found"
								? true
								: false
					},
					{
						id: "user-found",
						options: [
							{
								value: "same department",
								label: "Same Department",
								trigger: "registerd_first_time"
							},
							{
								value: "Other Department",
								label: "Other Department",
								trigger: ""
							}
						]
					},
					
					...exploreMore,
					
				]}
			/>
			
		</MyContext.Provider>
	);
}

export default SimpleForm;
