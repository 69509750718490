import React from "react";
import styled from "styled-components";
import { LogoIcon, ResetIcon, CloseIcon } from "./Icons";

const Wrapper = styled.div`
	background-color: #1782B8;
	color: #fff;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.logo-header {
		display: flex;
		align-items: center;
	}

	.logo-header svg {
		margin-right: 10px;
	}

	.logo-header h5 {
		position: relative;
		top: 6px;
   	font-family: 'Poppins';
		font-weight: bold;
	}

	svg {
		margin-left: 10px;
		height: 24px;
		width: 24px;
	}

	p {
		font-weight: bold;
	}
`;

export default ({ clearChat, hideChat }) => {
	return (
		<Wrapper>
			<div className="logo-header">
				<LogoIcon />
				<h5>NU Fertility</h5>
			</div>

			<div className="icon-set">
				<ResetIcon onClick={clearChat} />
				<CloseIcon onClick={hideChat} />
			</div>
		</Wrapper>
	);
};
